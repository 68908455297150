import { BaseListResponseDto, PaginationQueryParams, SortDirectionQueryParams } from './common.dtos';

export interface AchRelationshipDto {
  id: number;
  accountId: number;
  bankRoutingNumber: number;
  bankAccount: string;
  bankName: string;
  bankAccountOwnerName: string;
  bankAccountType: string;
  nickName: string;
  approvalMethod: string;
  relationshipId?: number;
  state?: string;
  approval?: {
    approvalTime: string;
    userName: string;
    userEntity: string;
    userClass: string;
  };
  createDate: string;
  lastModifiedDate?: Date;
  cancellation?: {
    comment: string;
    reason: string;
  };
  deletedDate?: string;
  status: string;
  adminNote?: string; // NOTE: admin user information only
  adminNotes?: {
    // NOTE: admin user information only
    id: number;
    text: string;
    createBy: string;
    createDate: string;
  }[];
}

export interface AchRelationshipDetailsDto {
  relationshipId: number;
  accountId: number;
  accountName: string;
  accountNumber: string;
  accountTitle: string;
  status: string;
  approvalMethod: string;
  date: string;
  bankAccountOwnerNameSecret: string;
  bankName: string;
  text: string;
  reviewAddresses: {
    street: string;
    city: string;
    stateOrRegion: string;
    postalCode: string;
    country: string;
    isPrimary: boolean;
  }[];
  reviewEmails: string[];
  reviewPhoneNumbers: string[];
  created: {
    date: string;
    name: string;
    userId: number;
  };
  lastModified: {
    date: string;
    name: string;
    userId: number;
  };
  adminNote: string;
  accountStatus: string;
  repCode: string;
  bankRiskScore: null | number;
  bankAccountBalance: number;
  bankAccountTransactionDate: string;
}

export interface SearchAchRelationshipItemDto {
  accountId: number;
  accountName: string;
  accountNumber: string;
  accountTitle: string;
  adminNote: string;
  status: string;
  type: string;
  date: Date;
  relationship: {
    relationshipId: number;
    approvalMethod: string;
    bankName: string;
    bankAccountOwnerNameSecret: string;
    text: string;
  };
  accountStatus: string;
  repCode: string;
}

export interface BaseAchRelationshipParamsDto {
  accountId: number;
}

export interface BaseAchRelationshipQueryParamsDto {
  relationshipId: string;
}

export interface CreateAchRelationshipBodyDto {
  // TODO: add body params
}

export interface CreateAchRelationshipDto {
  params: BaseAchRelationshipParamsDto;
  body: CreateAchRelationshipBodyDto;
}

export enum SearchAchRelationshipSortByQueryParamTypeDto {
  Date = 'Date',
  AccountNumber = 'AccountNumber',
  AccountTitle = 'AccountTitle',
  AccountName = 'AccountName',
}

export interface SearchAchRelationshipListQueryParamsDto
  extends Partial<SortDirectionQueryParams>,
    Partial<PaginationQueryParams> {
  filterBy?: { query?: string; status?: string };
  sortBy?: SearchAchRelationshipSortByQueryParamTypeDto;
}

export interface SearchAchRelationshipListDto {
  queryParams?: SearchAchRelationshipListQueryParamsDto;
}

export interface SearchAchRelationshipListResponseDto extends BaseListResponseDto<SearchAchRelationshipItemDto[]> {}

export interface GetAchRelationshipListQueryParamsDto {
  state?: string;
}

export interface GetAchRelationshipListDto {
  params: BaseAchRelationshipParamsDto;
  queryParams?: GetAchRelationshipListQueryParamsDto;
}

export type UnformattedGetAchRelationshipListResponseDto = {
  tradingBlockACHRelationship: AchRelationshipDto;
  tradingBlockPlaidInstitution?: {
    institutionId: string;
    name?: string;
    logo?: string;
  };
}[];

export interface GetAchRelationshipListResponseDto
  extends BaseListResponseDto<
    {
      tradingBlockACHRelationship: AchRelationshipDto;
      tradingBlockPlaidInstitution?: {
        institutionId: string;
        name?: string;
        logo?: string;
      };
    }[]
  > {}

export interface GetAchRelationshipParamsDto {
  id: string;
}

export interface GetAchRelationshipDto {
  params: GetAchRelationshipParamsDto;
}

export interface ApproveAchRelationshipParamsDto extends BaseAchRelationshipParamsDto {}

export interface ApproveAchRelationshipQueryParamsDto extends BaseAchRelationshipQueryParamsDto {}

export interface ApproveAchRelationshipBodyDto {
  adminNote?: string;
  note?: string;
}

export interface ApproveAchRelationshipDto {
  queryParams: ApproveAchRelationshipQueryParamsDto;
  params: ApproveAchRelationshipParamsDto;
  body: ApproveAchRelationshipBodyDto;
}

export interface CancelAchRelationshipParamsDto extends BaseAchRelationshipParamsDto {}

export interface CancelAchRelationshipQueryParamsDto extends BaseAchRelationshipQueryParamsDto {}

export interface CancelAchRelationshipBodyDto {
  comment?: string;
}

export interface CancelAchRelationshipDto {
  queryParams: CancelAchRelationshipQueryParamsDto;
  params: CancelAchRelationshipParamsDto;
  body: CancelAchRelationshipBodyDto;
}

export interface RejectAchRelationshipParamsDto extends BaseAchRelationshipParamsDto {}

export interface RejectAchRelationshipQueryParamsDto extends BaseAchRelationshipQueryParamsDto {}

export interface RejectAchRelationshipBodyDto {
  adminNote?: string;
  note?: string;
}

export interface RejectAchRelationshipDto {
  queryParams: RejectAchRelationshipQueryParamsDto;
  params: RejectAchRelationshipParamsDto;
  body: RejectAchRelationshipBodyDto;
}

export interface UpdateAchRelationshipParamsDto extends BaseAchRelationshipParamsDto {}

export interface UpdateAchRelationshipQueryParamsDto extends BaseAchRelationshipQueryParamsDto {}

export interface UpdateAchRelationshipBodyDto {
  nickName?: string;
  adminNote?: string;
  note?: string;
}

export interface UpdateAchRelationshipDto {
  queryParams: UpdateAchRelationshipQueryParamsDto;
  params: UpdateAchRelationshipParamsDto;
  body: UpdateAchRelationshipBodyDto;
}
