import * as React from 'react';

import { DateField, EmailField, FunctionField, Labeled, SelectField, TextField } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import { maritalStatus } from '../../../../constants/choices/user.choices';
import { UserBaseDto, UserDto } from '../../../../dtos/users.dtos';
import { CountryField, PhoneField } from '../../../Fields';
import StatusField from '../../../Fields/StatusField';

export const PersonalInformationShow = (record: UserBaseDto) => {
  const {
    account: { primaryAccountHolder },
    ...userData
  } = record;

  return (
    <div className='showContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>Personal Information</h2>
        </Col>
      </Row>
      <Row gutterWidth={10}>
        <Col md={1}>
          <Labeled label='First name'>
            <TextField source='firstName' />
          </Labeled>
        </Col>
        <Col md={2}>
          <Labeled label='Middle Initial or Middle Name'>
            <TextField source='account.primaryAccountHolder.middleName' />
          </Labeled>
        </Col>
        <Col md={1}>
          <Labeled label='Last name'>
            <TextField source='lastName' />
          </Labeled>
        </Col>
        <Col md={1}>
          <Labeled label='Suffix'>
            <TextField source='account.primaryAccountHolder.suffix' />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Email'>
            <EmailField source='email' record={userData} />
          </Labeled>
        </Col>

        <Col md={6}>
          <Labeled label='Phone'>
            <PhoneField source='phoneNumber' record={userData} />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Tax id'>
            <TextField source='taxId' record={primaryAccountHolder} />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='Date of birth'>
            <DateField source='dateOfBirth' record={primaryAccountHolder} options={{ timeZone: 'UTC' }} />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Marital status'>
            <SelectField choices={maritalStatus} record={primaryAccountHolder} source='maritalStatus' />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='Number of Dependents'>
            <TextField source='numberOfDependents' record={primaryAccountHolder} />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Birth country'>
            <CountryField record={primaryAccountHolder} source='birthCountry' />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='Citizenship country'>
            <CountryField record={primaryAccountHolder} source='citizenshipCountry' />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Sign up status'>
            {
              <StatusField
                record={userData}
                source='signUpStatus'
                success='done'
                warning={['addressStep', 'securityStep']}
              />
            }
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='MFA status'>
            <FunctionField
              render={({ isMfaEnabled }: UserDto) => <span>{isMfaEnabled ? 'Enabled' : 'Disabled'}</span>}
            />
          </Labeled>
        </Col>
      </Row>
    </div>
  );
};
