import {
  SearchTransferItemDto,
  TradingBlockTransferDirectionDto,
  TradingBlockTransferMechanismDto,
  TradingBlockTransferStateDto,
} from 'src/dtos/transfers.dtos';

export class TransferDirection {
  private _value: TradingBlockTransferDirectionDto;

  constructor(type: TradingBlockTransferDirectionDto) {
    this._value = type;
  }

  get value(): TradingBlockTransferDirectionDto {
    return this._value;
  }

  get label(): string {
    return this._value;
  }

  get isDeposit(): boolean {
    return this._value === TradingBlockTransferDirectionDto.Incoming;
  }

  get isWithdrawal(): boolean {
    return this._value === TradingBlockTransferDirectionDto.Outgoing;
  }
}

export enum TransferStatusLabel {
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export class TransferStatus {
  private _value: TradingBlockTransferStateDto;
  private _label: TransferStatusLabel;

  constructor(state: TradingBlockTransferStateDto) {
    this._value = state;
    this._label = this.findLabel();
  }

  private findLabel(): TransferStatusLabel {
    switch (this._value) {
      case TradingBlockTransferStateDto.FundsPosted:
      case TradingBlockTransferStateDto.Returned:
        return TransferStatusLabel.PENDING;
      case TradingBlockTransferStateDto.Rejected:
      case TradingBlockTransferStateDto.CreateRejected:
        return TransferStatusLabel.REJECTED;
      case TradingBlockTransferStateDto.Complete:
        return TransferStatusLabel.COMPLETED;
      case TradingBlockTransferStateDto.Canceled:
        return TransferStatusLabel.CANCELLED;
      default:
        return TransferStatusLabel.PROCESSING;
    }
  }

  get value(): TradingBlockTransferStateDto {
    return this._value;
  }

  get label(): TransferStatusLabel {
    return this._label;
  }

  get isProcessing(): boolean {
    return this._label === TransferStatusLabel.PROCESSING;
  }

  get isPending(): boolean {
    return this._label === TransferStatusLabel.PENDING;
  }

  get isCompleted() {
    return this._label === TransferStatusLabel.COMPLETED;
  }

  get isCancelled() {
    return this._label === TransferStatusLabel.CANCELLED;
  }

  get isRejected() {
    return this._label === TransferStatusLabel.REJECTED;
  }
}

export interface Transfer {
  id: number;
  accountId: number;
  mechanism: TradingBlockTransferMechanismDto;
  direction: TransferDirection;
  status: TransferStatus;
  from?: string;
  to: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  bankName: string;
  plaidInstitutionId: string;
  bankLogo?: string;
  description: string;
}

export interface TransferDetails extends Omit<Transfer, 'plaidInstitutionId' | 'bankLogo'> {}

export interface SearchTransferItem extends Omit<SearchTransferItemDto, 'transfer'> {
  id: string;
  accountId: number;
  accountNumber: string;
  accountTitle: string;
  accountName: string;
  type: string;
  state: string;
  amount: number;
  bankAccountOwnerNameSecret: string;
  bankName: string;
  direction: string;
  mechanism: string;
  subaccountId?: number | null;
  text: string;
  transferId: number;
  accountStatus: string;
  repCode: string;
  date: string;
}
