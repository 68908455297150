import { DragDropElementTree } from '../../lib/ExportModal/models/export.models';

export const modelFields: DragDropElementTree[] = [
  {
    id: 'id',
    name: 'Order Id',
    isSelected: true,
  },
  {
    id: 'firstName',
    name: 'First Name',
    isSelected: true,
  },
  {
    id: 'lastName',
    name: 'Last Name',
    isSelected: true,
  },
  {
    id: 'addressLine1',
    name: 'Address Line 1',
    isSelected: true,
  },
  {
    id: 'addressLine2',
    name: 'Address Line 2',
    isSelected: true,
  },
  {
    id: 'city',
    name: 'City',
    isSelected: true,
  },
  {
    id: 'stateCode',
    name: 'State Code',
    isSelected: true,
  },
  {
    id: 'postalCode',
    name: 'Postal Code',
    isSelected: true,
  },
  {
    id: 'taxId',
    name: 'Tax ID',
    isSelected: true,
  },
  {
    id: 'quantity',
    name: 'Number of Shares',
    isSelected: true,
  },
  {
    id: 'price',
    name: 'Offer Price per Share',
    isSelected: true,
  },
  {
    id: 'totalCost',
    name: 'Requested Total Currency Amount',
    isSelected: false,
  },
  {
    id: 'createdAt',
    name: 'Created At',
    isSelected: true,
  },
  {
    id: 'email',
    name: 'Email',
    isSelected: true,
  },
  {
    id: 'offerName',
    name: 'Offer Name',
    isSelected: false,
  },
  {
    id: 'accountNumber',
    name: 'Account Number',
    isSelected: false,
  },
  {
    id: 'cashBalance',
    name: 'Cash Balance',
    isSelected: false,
  },
  {
    id: 'cashAvailable',
    name: 'Cash Available',
    isSelected: false,
  },
  {
    id: 'accreditation',
    name: 'Accreditation',
    isSelected: false,
  },
  {
    id: 'securityDeliveryOption',
    name: 'Security Delivery Option',
    isSelected: false,
  },
  {
    id: 'paymentType',
    name: 'Payment Type',
    isSelected: false,
  },
  {
    id: 'orderStatus',
    name: 'Order Status',
    isSelected: false,
  },
  {
    id: 'offerType',
    name: 'Offer Type',
    isSelected: false,
  },
  {
    id: 'offerStatus',
    name: 'Offer Status',
    isSelected: false,
  },
  {
    id: 'offerIndustry',
    name: 'Offer Industry',
    isSelected: false,
  },
  {
    id: 'offerSector',
    name: 'Offer Sector',
    isSelected: false,
  },
  {
    id: 'offerCategory',
    name: 'Offer Category',
    isSelected: false,
  },
  {
    id: 'securityType',
    name: 'Security Type',
    isSelected: false,
  },
];
