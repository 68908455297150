import { TransferUrlConstant } from 'src/constants';
import {
  AdminTransferDetailsDto,
  CancelTransferDto,
  FirmApproveTransferDto,
  GetTransferDto,
  GetTransferListDto,
  GetTransferListResponseDto,
  PlaidInstitutionDto,
  RejectTransferDto,
  RepApproveTransferDto,
  SearchTransferListDto,
  SearchTransferListResponseDto,
  TransferDetailsDto,
  TransferDto,
  UpdateTransferDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class TransfersApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async search({ queryParams }: SearchTransferListDto): Promise<SearchTransferListResponseDto> {
    return await httpRequest<SearchTransferListResponseDto>({
      method: 'GET',
      url: TransferUrlConstant.SEARCH,
      authToken: this._configService.authToken,
      queryParams,
    });
  }

  async list({ params, queryParams }: GetTransferListDto): Promise<GetTransferListResponseDto> {
    const url = replacePlaceholders(TransferUrlConstant.LIST, params);

    // TODO: fix server response dto to match list response type
    const response = await httpRequest<{
      transactions: {
        tradingBlockTransfer: TransferDto;
        tradingBlockPlaidInstitution: PlaidInstitutionDto;
      }[];
      totalTransactions: number;
    }>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
      queryParams,
    });

    return {
      data: response.transactions,
      total: response.totalTransactions,
    };
  }

  async retrieve({ params }: GetTransferDto): Promise<AdminTransferDetailsDto> {
    const url = replacePlaceholders(TransferUrlConstant.RETRIEVE, params);

    return await httpRequest<AdminTransferDetailsDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async firmApprove({ params, body }: FirmApproveTransferDto): Promise<TransferDetailsDto> {
    const url = replacePlaceholders(TransferUrlConstant.FIRM_APPROVE, params);

    return await httpRequest<TransferDetailsDto>({
      method: 'PUT',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async repApprove({ params, body }: RepApproveTransferDto): Promise<TransferDetailsDto> {
    const url = replacePlaceholders(TransferUrlConstant.REP_APPROVE, params);

    return await httpRequest<TransferDetailsDto>({
      method: 'PUT',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async cancel({ params, body }: CancelTransferDto): Promise<TransferDetailsDto> {
    const url = replacePlaceholders(TransferUrlConstant.CANCEL, params);

    return await httpRequest<TransferDetailsDto>({
      method: 'PUT',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async reject({ params, body }: RejectTransferDto): Promise<TransferDetailsDto> {
    const url = replacePlaceholders(TransferUrlConstant.REJECT, params);

    return await httpRequest<TransferDetailsDto>({
      method: 'PUT',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async update({ params, body }: UpdateTransferDto): Promise<TransferDetailsDto> {
    const url = replacePlaceholders(TransferUrlConstant.UPDATE, params);

    return await httpRequest<TransferDetailsDto>({
      method: 'PUT',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }
}
